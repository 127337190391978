import React, { useState, useEffect, Fragment, useRef } from "react";

import left from "../Assets/icons/Left.svg";
import iconOpen from "../Assets/icons/Open.svg";
import iconClosed from "../Assets/icons/Closed.svg";

import ViewStaffBlock from "./CustomBlocks/ViewStaffBlock";
import ResponseDisplay from "./CustomBlocks/ResponseDisplay";

// import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Row } from "react-bootstrap";

const InspectForm = ({ history, match }) => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true)
  const [showEmployees, setShowEmployees] = useState(true);
  const [showQuestions, setShowQuestions] = useState(true);
  const [listOpen, setListOpen] = useState(false);
  const [filter, setFilter] = useState({})
  const activeEmployeeIcon = showEmployees ? iconOpen : iconClosed;
  const activeQuestionsIcon = showQuestions ? iconOpen : iconClosed;

  useEffect(() => {
    async function fetchData() {
      const data = {
        FormId: match.params.FormId,
        EditionId: match.params.EditionId,
        DepartmentId: match.params.DepartmentId
      }

      const formData = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/form/GetFormData`, data
      );
      setFormData(formData.data);
      setLoading(false)
    }
    fetchData();
  }, [match.params.FormId, match.params.EditionId, match.params.DepartmentId]);

  const sendRemainderHandler = async () => {
    const data = [...formData.AnswerRate.EmployeesNotAnswered].map(obj => ({ ...obj, EditionId: parseInt(match.params.EditionId), EmployeeId: obj.Id }))
    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/SendReminder`, data
    );
  }

  const toggleListOpen = () => {
    setListOpen(!listOpen);
  }

  const listRef = useRef();

  const handleClickOutside = (e) => {
    if (!listRef.current.contains(e.target)) {
      setListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const menuClickHandler = (EmailAddress) => {
    setFilter({ ...filter, EmailAddress })
    setListOpen(false);
  }

  const activeButton = "App__button App__button--small App__button--green"
  const inactiveButton = "App__button App__button--small App__button--light-grey"

  let filteredAnswers = formData.TextAnswers
  if (Object.keys(filter).length > 0) {
    filteredAnswers = filteredAnswers.filter(x => (
      x.EmailAddress === filter.EmailAddress
    ))
  }

  return (
    !loading && (
      <div className="inspectForm">
        <div
          className="inspectForm__top-row"
          onClick={() => history.goBack()}
        >
          <img src={left} alt="tillbaka" className="inspectForm__back-icon" />
          Tillbaka
        </div>
        <div className="inspectForm__heading--large-row">
          <h3
            className="App__dashboard__heading--large"
            style={{ borderBottom: "none" }}
          >
            {formData.Form.Name || 'Titel saknas'}
          </h3>
          {moment(formData.Form.DateCreated).format("DD.MM.YYYY - HH.mm") || 'Tid saknas'}
        </div>
        <p className="inspectForm__info-text">
          {formData.Form.Description || 'Info text saknas.'}
        </p>
        <div className="inspectForm__heading-row">
          <h4
            className="App__dashboard__heading"
            style={{ borderBottom: "none" }}
          >
            Deltagare
          </h4>
          <img
            src={activeEmployeeIcon}
            alt="öppna deltagare"
            className="inspectForm__heading-row__icon"
            onClick={() => setShowEmployees(!showEmployees)}
          />
        </div>
        {showEmployees && (
          <ViewStaffBlock
            callToAction={false}
            theme={"dark"}
            title={"Besvarat"}
            employees={formData.AnswerRate.EmployeesAnswered}
            ableToEditStaff={false}
          />
        )}
        {showEmployees && (
          <ViewStaffBlock
            callToActionText={"Skicka påminnelse"}
            title={"Väntar svar"}
            employees={formData.AnswerRate.EmployeesNotAnswered}
            callToActionHandler={sendRemainderHandler}
            ableToEditStaff={false}
          />
        )}
        <div className="inspectForm__heading-row">
          <h4
            className="App__dashboard__heading"
            style={{ borderBottom: "none" }}
          >
            Frågor
          </h4>
          <img
            src={activeQuestionsIcon}
            alt="öppna deltagare"
            className="inspectForm__heading-row__icon"
            onClick={() => setShowQuestions(!showQuestions)}
          />
        </div>
        {showQuestions && (
          <Fragment>
            <p className="inspectForm__info-text">Visa svar för</p>
            <Row noGutters={true}>
              <button className={Object.keys(filter).length > 0 ? inactiveButton : activeButton} onClick={() => setFilter({})}>
                Alla
            </button>
              <div className="inspectForm__dropdown" ref={listRef}>
                <button
                  className={Object.keys(filter).length === 0 ? inactiveButton : activeButton}
                  style={{ marginLeft: "1rem" }}
                  onClick={toggleListOpen}
                >
                  På person
                </button>
                {listOpen && <ul className="inspectForm__dropdown__menu">
                  {formData.AnswerRate.EmployeesAnswered.map(employee => (
                    <li className="inspectForm__dropdown__menu-option" onClick={() => menuClickHandler(employee.EmailAddress)}>{employee.EmailAddress}</li>
                  ))}
                </ul>}
              </div>
            </Row>
            <h4 className="App__dashboard__heading App__dashboard__heading--no-border mt-4">
              {Object.keys(filter).length > 0 ? `Kompletterande svar - ${filter.EmailAddress}` : "Kompletterande svar"}
            </h4>

            <div className="inspectForm__questions" id="orange-scroller">
              {Object.keys(formData).length > 0 &&
                filteredAnswers.map((answer, i) => (
                  <div className="inspectForm__questions__white-box" key={i}>
                    <div>{answer.Question}</div>
                    <div className="inspectForm__info-text">
                      {answer.TextAnswer}
                    </div>
                    {Object.keys(filter).length === 0  && <div className="inspectForm__questions__white-box__name">
                      {answer.EmailAddress}
                    </div>}
                  </div>
                ))}
            </div>


          </Fragment>
        )}
        <ResponseDisplay answers={formData.IntAnswers} />
      </div >
    )
  );
};

export default InspectForm;
