import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HomeViewBottomSection = () => {
  return (
    <section className="HomeView__bottomsection">
      <Container>
        <Row>
          <Col lg={5}>
            <h2 className="HomeView__bottomsection__heading">
              Vad vi gör - och det fungerar
            </h2>
            <p className="HomeView__bottomsection__text">
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
            <button className="HomeView__bottomsection__button">Läs mer</button>
          </Col>
          <Col lg={7}>
            <div className="HomeView__bottomsection__dashboard-image">
              Bild på dashboard
            </div>
          </Col>
        </Row>
        <Row className="HomeView__bottomsection__row">
          <Col lg={4} md={4} sm={12}>
          <figure className="HomeView__bottomsection__columns__figure">
            <img
              className="HomeView__bottomsection__columns__image"
              src={"./Images/HomeView/Rectangle_18.jpg"}
              alt="kontorsbild"
            /></figure>
            <h4 className="HomeView__bottomsection__columns__heading">
              Håll alla i sync
            </h4>
            <p className="HomeView__bottomsection__columns__text">
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s,
            </p>
          </Col>
          <Col lg={4} md={4} sm={6}>
            <figure className="HomeView__bottomsection__columns__figure">
              <img
                className="HomeView__bottomsection__columns__image"
                src={"./Images/HomeView/Rectangle_19.jpg"}
                alt="kontorsbild"
              />
            </figure>
            <h4 className="HomeView__bottomsection__columns__heading">
              Få idéer och feedback
            </h4>
            <p className="HomeView__bottomsection__columns__text">
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s,
            </p>
          </Col>
          <Col lg={4} md={4}  sm={6}>
          <figure className="HomeView__bottomsection__columns__figure">
            <img
              className="HomeView__bottomsection__columns__image"
              src={"./Images/HomeView/Rectangle_20.jpg"}
              alt="kontorsbild"
            />
          </figure>
            <h4 className="HomeView__bottomsection__columns__heading">
              Lättöverskådligt och flexibelt
            </h4>
            <p className="HomeView__bottomsection__columns__text">
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s,
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeViewBottomSection;
