import { createSlice } from "@reduxjs/toolkit";
import axios from 'axios';

export const formSlice = createSlice({
  name: "form",

  initialState: {
    forms: [],
    formLoading: true,
    formSubmit: false,
    formError: false
  },

  reducers: {
    setForms: (state, action) => {
      state.forms = action.payload;
      state.formLoading = false;
    },
    submitForm: (state) => {
      state.formLoading = true;
    },
    submitFormSuccess: (state) => {
      state.formLoading = false;
      state.formSubmit = true;
    },
    submitFormFailure: (state) => {
      state.formLoading = false;
      state.error = true;
    }
  },
});

export default formSlice.reducer;

export const {
  setForms,
  submitForm,
  submitFormSuccess,
  submitFormFailure
} = formSlice.actions

export const formsSelector = (state) => state.form;

export function submitUserForm(data) {
  return async (dispatch) => {
    dispatch(submitForm());

    try {
      const response = await axios.post(`${process.env.REACT_APP_DOMAIN}/api/form/SubmitForm`, data
      )
      console.log(response.data)
      dispatch(submitFormSuccess(response.data));
    } catch (error) {
      dispatch(submitFormFailure)
    }
  }
};