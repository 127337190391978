import { createSlice, current } from "@reduxjs/toolkit";
import axios from "axios";

const departmentSlice = createSlice({
  name: "departments",
  initialState: {
    loading: false,
    hasErrors: false,
    departments: [],
  },
  reducers: {
    getDepartments: (state) => {
      state.loading = true;
    },
    getDepartmentsSuccess: (state, { payload }) => {
      state.departments = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getDepartmentsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    addDepartment: (state, {payload}) => {
      state.departments = [...state.departments, payload]
    },
    addEmployee: (state, action) => {
      // FIND THE INDEX OF THE DEPARTMENT AND EMPLOYEE ARRAY
      const departmentIndex = current(state.departments).findIndex((x) => {
        return x.Id === action.payload.DepartmentId;
      });
      // INSERT EMPLOYEE IN NEW SPOT
      state.departments[departmentIndex].Employees = [
        ...state.departments[departmentIndex].Employees,
        action.payload,
      ];
    },
    updateEmployee: (state, action) => {
      // FIND THE INDEX OF THE DEPARTMENT AND EMPLOYEE ARRAY
      const departmentIndex = current(state.departments).findIndex((x) => {
        return x.Id === action.payload.OldDepartmentId;
      });
      const employeeIndex = current(
        state.departments[departmentIndex].Employees
      ).findIndex((x) => x.Id === action.payload.Id);

      // TEST IF THERE IS A NEW DEPARTMENT ID
      const needsToBeMoved =
        action.payload.OldDepartmentId !== action.payload.DepartmentId;

      // UPDATE THE EMPLOYEE
      state.departments[departmentIndex].Employees[employeeIndex] =
        action.payload;

      if (needsToBeMoved) {
        const newDeparmentIndex = current(state.departments).findIndex((x) => {
          return x.Id === action.payload.DepartmentId;
        });
        // REMOVE EMPLOYEE FROM OLD SPOT
        state.departments[departmentIndex].Employees = [
          ...state.departments[departmentIndex].Employees.slice(
            0,
            employeeIndex
          ),
          ...state.departments[departmentIndex].Employees.slice(
            employeeIndex + 1
          ),
        ];
        // INSERT EMPLOYEE IN NEW SPOT
        state.departments[newDeparmentIndex].Employees = [
          ...state.departments[newDeparmentIndex].Employees,
          action.payload,
        ];
      }
    },
    removeEmployee: (state, action) => {
      const departmentIndex = current(state.departments).findIndex((x) => {
        return x.Id === action.payload.DepartmentId;
      });
      const employeeIndex = current(
        state.departments[departmentIndex].Employees
      ).findIndex((x) => x.EmailAddress === action.payload.EmailAddress);
      // REMOVE EMPLOYEE FROM OLD SPOT
      state.departments[departmentIndex].Employees = [
        ...state.departments[departmentIndex].Employees.slice(0, employeeIndex),
        ...state.departments[departmentIndex].Employees.slice(
          employeeIndex + 1
        ),
      ];
    },
  },
});

export default departmentSlice.reducer;

// Three actions generated from the slice
export const {
  getDepartments,
  getDepartmentsSuccess,
  getDepartmentsFailure,
  addDepartment,
  addEmployee,
  updateEmployee,
  removeEmployee,
} = departmentSlice.actions;

// Export A selector
export const departmentsSelector = (state) => state.departments;

export function fetchDepartments() {
  return async (dispatch) => {
    dispatch(getDepartments());

    try {
      const response = await axios(
        `${process.env.REACT_APP_DOMAIN}/api/dashboard/GetDepartmentsAndEmployees?organizationId=1`
      );
      dispatch(getDepartmentsSuccess(response.data));
    } catch (error) {
      dispatch(getDepartmentsFailure());
    }
  };
}

export function addDepartmentAction(name, organizationId) {
  return async (dispatch) => {
    const response = await axios.get(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/CreateDepartment?name=${name}&organizationid=${organizationId}`
    );
    dispatch(addDepartment(response.data));
  };
}

export function editEmployeeAction(data) {
  return async (dispatch) => {
    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/UpdateEmployee`,
      data
    );
    dispatch(updateEmployee(data));
  };
}

export function addEmployeeAction(data) {
  return async (dispatch) => {
    await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/AddEmployee`,
      data
    );
    dispatch(addEmployee(data));
  };
}

export function removeEmployeeAction(email, DepartmentId) {
  return async (dispatch) => {
    const response = await axios.get(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/DeleteEmployee?emailAddress=${email}`
    );
    dispatch(removeEmployee({EmailAddress: response.data, DepartmentId}));
  };
}
