import React, { useState } from "react";
import { useSelector } from "react-redux";

import Modal from "../../../Components/SendFormModal";
import FormOverviewCard from "../../../Components/FormOverviewCard";

const DashboardSendouts = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const forms = useSelector((state) => state.form.forms);

  const latestForms = forms.slice(-3);

  return (
    <div
      className="dashboardStartView__container"
      style={{ backgroundColor: "#E5E5E5", minHeight: "800px" }}
    >
      <h3 className="App__dashboard__heading--large">Mina utskick</h3>
      <p className="App__dashboard__info-text App__dashboard__info-text--full-width">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id faucibus
        malesuada ultrices laoreet. Fringilla est justo,.
      </p>
      <button
        className="App__button App__button--orange App__button--small"
        onClick={() => setModalOpen(true)}
      >
        Skapa nytt utskick
      </button>
      <h4 className="App__dashboard__heading">Senaste undersökningarna</h4>
      <div className="App__dashboard-surveyOverview-container">
        {latestForms.length > 0 &&
          latestForms.map((form) => {
            return <FormOverviewCard key={form.Id} form={form} />;
          })}
      </div>
      <h4 className="App__dashboard__heading">Arkiv</h4>
      <div className="App__dashboard-surveyOverview-container">
        {forms.length > 0 &&
          forms.map((form) => {
            return <FormOverviewCard key={form.Id} form={form} />;
          })}
      </div>
      {modalOpen && <Modal setModalOpen={setModalOpen} />}
    </div>
  );
};

export default DashboardSendouts;
