import { useState } from "react";
function useFormState(initialValue) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const reset = () => {
    setValue("");
  };

  const setInitialValue = (value) => {
    setValue(value);
  };
  return [value, handleChange, reset, setInitialValue];
}

export default useFormState;
