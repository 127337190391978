import React from "react";
import { useState } from "react";

import iconOpen from "../../Assets/icons/Open.svg";
import iconClosed from "../../Assets/icons/Closed.svg";

import HandleEmployeeModal from "../HandleEmployeeModal";
import EditStaffCard from "../EditStaffCard";

import { useDispatch, useSelector } from "react-redux";
import {
  addEmployeeAction,
  departmentsSelector,
} from "../../Redux/DepartmentSlice";

const AddStaffBlock = ({
  department,
  theme = "light",
  callToAction = true,
  callToActionText = "Lägg till personal",
  title,
}) => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { departments } = useSelector(departmentsSelector);

  // ICON LOGIC
  const activeIcon = open ? iconOpen : iconClosed;
  const iconStatus = open ? "open" : "closed";

  // LOGIC FOR SETTING THE THEME OF THE COMPONENT
  let componentClass = "addStaffBlock";

  switch (theme) {
    case "dark":
      componentClass = "addStaffBlock addStaffBlock--dark";
      break;
    default:
      componentClass = "addStaffBlock";
      break;
  }

  const addEmployeeHandler = async (
    EmailAddress,
    FirstName,
    LastName,
    departmentName
  ) => {
    const newDepartmentArray = departments.filter(
      (x) => x.Name === departmentName
    );

    const data = {
      EmailAddress,
      FirstName,
      LastName,
      DepartmentId: newDepartmentArray[0].Id,
    };

    dispatch(addEmployeeAction(data));
  };

  return (
    <div className={componentClass}>
      <div className="addStaffBlock__top-row">
        <h4 className="addStaffBlock__title">{title || department.Name}</h4>
        <span className="addStaffBlock__title-info">
          {department.Employees.length} MEDLEMMAR
        </span>
      </div>
      <img
        src={activeIcon}
        alt="ikonbild"
        className={`addStaffBlock__icon addStaffBlock__icon--${iconStatus}`}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <div className="addStaffBlock__show-staff">
          <p className="addStaffBlock__show-staff__info-text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id faucibus
            malesuada ultrices laoreet. Fringilla est justo,.
          </p>

          <div className="addStaffBlock__show-staff__employee-list">
            {department.Employees.map((employee) => (
              <EditStaffCard
                staff={employee}
                key={employee.EmailAddress}
                department={department}
              />
            ))}
          </div>

          {callToAction && (
            <button
              className="App__button App__button--orange App__button--orange--small"
              onClick={() => setModalOpen(true)}
            >
              {callToActionText}
            </button>
          )}
        </div>
      )}
      {modalOpen && (
        <HandleEmployeeModal
          title={"Lägg till Personal"}
          setModalOpen={setModalOpen}
          handleEmployee={addEmployeeHandler}
          initialDepartment={department.Name}
        />
      )}
    </div>
  );
};

export default AddStaffBlock;
