import React from "react";
import { NavLink } from "react-router-dom";

const LeftNavbar = () => {
  return (
    <nav className="leftNavbar">
      <NavLink
        exact
        to="/dashboard"
        className="leftNavbar__link"
        activeClassName="leftNavbar__link--active"
      >
        Start
      </NavLink>
      <span
        style={{
          color: "#AFAFAF",
          fontSize: ".8rem",
          paddingLeft: "1.2rem",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        Företagsadministration
      </span>
      <NavLink
        to="/dashboard/personal"
        className="leftNavbar__link"
        activeClassName="leftNavbar__link--active"
      >
        Personal
      </NavLink>
      <NavLink
        to="/dashboard/minautskick"
        className="leftNavbar__link"
        activeClassName="leftNavbar__link--active"
      >
        Mina utskick
      </NavLink>
      <NavLink
        to="/dashboard/minamallar"
        className="leftNavbar__link"
        activeClassName="leftNavbar__link--active"
      >
        Dokument & mallar
      </NavLink>
    </nav>
  );
};

export default LeftNavbar;
