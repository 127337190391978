import React from "react";

import TopNavBar from "../../Components/TopNavbar";
import FaqViewTopSection from "../../Components/CustomBlocks/FaqViewTopSection";
import FaqViewBottomSection from "../../Components/CustomBlocks/FaqViewBottomSection";
import Footer from "../../Components/Footer";

const FaqView = () => {
  return (
    <div className="faqView">
      <TopNavBar />
      <FaqViewTopSection />
      <FaqViewBottomSection />
      <Footer />
    </div>
  );
};

export default FaqView;
