import React from "react";

import Navbar from "../../Components/TopNavbar";
import HomeViewTopSection from "../../Components/CustomBlocks/HomeViewTopSection";
import HomeViewMiddleSection from "../../Components/CustomBlocks/HomeViewMiddleSection";
import HomeViewBottomSection from "../../Components/CustomBlocks/HomeViewBottomSection";
import Footer from "../../Components/Footer";

const HomeView = () => {
  return (
    <div className="HomeView">
      <Navbar />
      <HomeViewTopSection />
      <HomeViewMiddleSection />
      <HomeViewBottomSection />
      <Footer />
    </div>
  );
};

export default HomeView;
