import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  return (
    <footer
      style={{
        height: "23rem",
        backgroundColor: "black",
        color: "white",
        lineHeight: "15.rem",
      }}
    >
      <Container>
        <Row style={{ paddingTop: "5rem" }}>
          <Col lg={3}>
            <span
              style={{
                fontSize: "1.5rem",
                fontFamily: "Sulphur Point",
                fontWeight: "700",
              }}
            >
              medarbetarpulsen.io
            </span>
            <p
              style={{
                marginTop: "1rem",
                fontFamily: "Open Sans",
                lineHeight: "1.5",
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
              faucibus malesuada ultrices laoreet. Fringilla est justo,
              elementum morbi risus ac molestie sed vitae.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
