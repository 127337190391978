import React from "react";
import { Link } from "react-router-dom";

import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, userSelector } from "../Redux/UserSlice";
import { Fragment } from "react";

const NavbarComponent = ({ history, backgroundColor = "background-color: #223b41" }) => {
  const {user, isLoggedIn} = useSelector(userSelector)
  const dispatch = useDispatch()
  
  const logoutHandler = () => {
    console.log('logoutHandler ran')
    dispatch(logoutUser())
  }

  return (

    <Container fluid className="topNavbar" style={{ backgroundColor }}>
      <Container>
        <Navbar variant="dark" className="p-0 pt-2" expand="md">
          <Navbar.Brand as={Link} to="/" className="App__brand">
            medarbetarpulsen.io
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            {isLoggedIn ? (
              <Fragment>
                <div className="topNavbar-username">
                  {user.Email}
                </div>
                <Nav.Link 
                  onClick={logoutHandler}
                  className="topNavbar-link topNavbar-link--orange"
                >
                  Logga ut
                </Nav.Link>
              </Fragment>)
              : (
                <Fragment>
                  <Nav.Link as={Link} to="/faq" className="topNavbar-link">
                    Vanliga Frågor
                  </Nav.Link>
                  <Nav.Link as={Link} to="/faq" className="topNavbar-link">
                    Personalhantering
                  </Nav.Link>
                  <Nav.Link as={Link} to="/faq" className="topNavbar-link">
                    Säkerhet
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/login"
                    className="topNavbar-link topNavbar-link--orange"
                  >
                    Logga in
                  </Nav.Link>
                </Fragment>
              )}

          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>
  );
};

export default NavbarComponent;
