import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HomeViewMiddleSection = () => {
  return (
    <section className="HomeView__middlesection">
      <Container style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col lg={7} md={6} sm={6} className="App__flex-center">
            <h3 className="HomeView__middlesection__heading ">
              Alla vinner på att personalen mår bra, och det hjälper vi till med
            </h3>
          </Col>

          <Col
            lg={4}
            md={6}
            sm={6}
            className="HomeView__middlesection__blackbox"
          >
            <h5 className="HomeView__middlesection__blackbox__heading">
              Hur mår alla, egentligen
            </h5>
            <p className="HomeView__middlesection__blackbox__text">
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeViewMiddleSection;
