import React from "react";

import useFormState from "../../../Hooks/useFormState";
import left from "../../../Assets/icons/Left.svg";

import CreateQuestionsBlock from "../../../Components/CustomBlocks/CreateQuestionBlock/CreateQuestionsBlock";

const DashboardCreateForm = ({ history }) => {
  const [name, setName] = useFormState("");
  const [description, setDescription] = useFormState("");

  return (
    <div
      className="dashboardStartView__container"
      style={{ backgroundColor: "#E5E5E5", minHeight: "800px" }}
    >
      <div className="inspectSurvey__top-row" onClick={() => history.goBack()}>
        <img src={left} alt="tillbaka" className="inspectSurvey__back-icon" />
        Tillbaka
      </div>
      <h4 className="App__dashboard__heading mt-3">Ny mall</h4>
      <div className="dashboardCreateForm__green-box">
        <h4 className="dashboardCreateForm__green-box__heading">Allmänt</h4>
        <div className="dashboardCreateForm__green-box__col">
          <p
            className="App__dashboard__info-text App__dashboard__info-text--full-width"
            style={{ marginTop: 0 }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id faucibus
            malesuada ultrices laoreet. Fringilla est justo,.
          </p>
          <input
            className="dashboardCreateForm__green-box__input"
            type="text"
            placeholder="Namn på mallen"
            value={name}
            onChange={setName}
          />
          <textarea
            className="dashboardCreateForm__green-box__input"
            type="text"
            placeholder="Beskriv anledningen för utskicket"
            rows="10"
            value={description}
            onChange={setDescription}
          />
        </div>
        <div className="dashboardCreateForm__green-box__col">
          <div className="dashboardCreateForm__green-box__black-box">
            <h4
              className="App__dashboard__heading App__dashboard__heading--no-border App__dashboard__heading--green"
              style={{ marginTop: 0 }}
            >
              Tänk på detta
            </h4>
            <p className="App__dashboard__info-text App__dashboard__info-text--full-width">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
              faucibus malesuada ultrices laoreet. Fringilla est justo,
              elementum morbi risus ac molestie sed vitae. Nisl neque, lectus
              scelerisque vitae ac nibh id cursus. Feugiat leo mi, sed lectus.
            </p>
          </div>
        </div>
      </div>
      <CreateQuestionsBlock />
    </div>
  );
};

export default DashboardCreateForm;
