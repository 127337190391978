import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HomeViewTopSection = () => {
  return (
    <section className="HomeView__topsection">
      <Container>
        <Row>
          <Col lg={7}>
            <h1 className="HomeView__topsection__heading-1">
              Vad säger vem om vad?
              <br />
              Få svaret idag
            </h1>
            <p
              className="HomeView__topsection__text"
              style={{ marginBottom: "4.25rem" }}
            >
              Få dom senaste uppdateringarna!
            </p>
          </Col>
          <Col lg={5}></Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeViewTopSection;
