import React from "react";

const CreateQuestionsBlockQuestion = ({
  index,
  changeStringAnswerHandler,
  changeIntAnswerHandler,
  question,
}) => {
  const valueArray = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  return (
    <div className="displayQuestionsBlock__question">
      <div className="displayQuestionsBlock__white-box">
        <div className="displayQuestionsBlock__white-box__question">
          <div className="displayQuestionsBlock__white-box__heading">
            {index + 1}. {question.Question}
          </div>
          {question.FormQuestionTypeId === 2 ? (
            <textarea
              className="displayQuestionsBlock__white-box__textarea"
              type="text"
              placeholder="Skriv ditt svar här"
              value={question.StringAnswer}
              onChange={(e) => changeStringAnswerHandler(e, index)}
              rows={5}
              name="question"
              required
            />
          ) : (
            <div className="displayQuestionsBlock__white-box__radio-row">
              <span className="displayQuestionsBlock__white-box__radio-row__bad App-mobile-hide">
                Dåligt
              </span>
              {valueArray.map((x, i) => (
                <div
                  className="displayQuestionsBlock__white-box__radio-row__col"
                  key={i}
                >
                  <span className="displayQuestionsBlock__white-box__radio-row__col__num">
                    {x}
                  </span>
                  <input
                    type="radio"
                    checked={x === question.IntAnswer}
                    onChange={(e) => changeIntAnswerHandler(e, index)}
                    key={i}
                    value={x}
                    required
                  />
                  <div
                    className="displayQuestionsBlock__white-box__radio-row__check"
                    onClick={(e) => {
                      return changeIntAnswerHandler(e, index);
                    }}
                    value={x}
                  ></div>
                </div>
              ))}
              <span className="displayQuestionsBlock__white-box__radio-row__good App-mobile-hide">
                Mycket Bra
              </span>
              <div className="displayQuestionsBlock__white-box__radio-row__mobile-info App-mobile-show">
                <div>1 = Dåligt</div> <div>10 = Bra</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateQuestionsBlockQuestion;
