import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddStaffBlock from "../../../Components/CustomBlocks/AddStaffBlock";
import { addDepartmentAction, departmentsSelector } from "../../../Redux/DepartmentSlice";
import plus from '../../../Assets/icons/Plus.svg'
import EditDepartmentModal from "../../../Components/EditDepartmentModal";

const DashboardStaff = () => {
  const { departments, loading } = useSelector(departmentsSelector);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const addDepartmentHandler = async (e, name, organizationId) => {
    e.preventDefault();
    console.log('addDepartmentHandler')
    addDepartmentAction(name, organizationId)
    setEditModalOpen(false)
  }

  return (
    <div
      className="dashboardStartView__container"
      style={{ backgroundColor: "#E5E5E5", minHeight: "800px" }}
    >
      <h3
        className="App__dashboard__heading--large"
        style={{ display: 'flex', justifyContent: 'space-between' }}
        onClick={() => setEditModalOpen(true)}
        >Personal
      <span
          className="App__dashboard__heading__subtext">Lägg till avdelning<img className="App__dashboard__heading__subtext__icon" src={plus} alt="lägg till avdelning" /></span></h3>

      {loading
        ? "laddar"
        : departments.length > 0 &&
        departments.map((department) => (
          <AddStaffBlock key={department.Id} department={department} />
        ))}
        {editModalOpen && <EditDepartmentModal editModalOpen={setEditModalOpen} addDepartmentHandler={addDepartmentHandler}/>}
    </div>
  );
};

export default DashboardStaff;
