import { Route, Switch } from "react-router-dom";

// ALL CSS
import "./Assets/scss/main.scss";

// VIEWS
import Index from "./Views/Home/HomeView";
import FormView from "./Views/Form/FormView";
import FaqView from "./Views/FAQ/FaqView";
import LoginView from "./Views/Login/LoginView";
import DashboardView from "./Views/Dashboard/DashboardView";
import ResetView from "./Views/Reset/ResetView";

function App() {
  // Fetch template
  // useEffect(() => {
  //   const go = async () => {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_DOMAIN}/api/dashboard/GetDepartments?organizationId=1`
  //     );
  //     console.log(await response.text());
  //   };
  //   go();
  // }, []);

  return (
    <div className="App">
      <Switch>
        <Route path="/dashboard" component={DashboardView} />
        <Route path="/form/:Token" component={FormView} />
        <Route exact path="/login" component={LoginView} />
        <Route exact path="/reset/:Token" component={ResetView} />
        <Route exact path="/faq" component={FaqView} />
        <Route exact path="/" component={Index} />
      </Switch>
    </div>
  );
}

export default App;
