import React, { useState } from "react";
import { useSelector } from "react-redux";
import useFormState from "../Hooks/useFormState";
import { departmentsSelector } from "../Redux/DepartmentSlice";
import CustomDropdown from "./CustomDropdown";

const Modal = ({
  setModalOpen,
  handleEmployee,
  title,
  buttonText,
  initialFirstName,
  initialLastName,
  initialEmail,
  initialDepartment,
}) => {
  const [firstName, setFirstName] = useFormState(initialFirstName || "");
  const [lastName, setLastName] = useFormState(initialLastName || "");
  const [emailAddress, setEmailAddressName] = useFormState(initialEmail || "");
  const [department, setDepartment] = useState(initialDepartment || "");
  const { departments } = useSelector(departmentsSelector);

  const handleSubmit = () => {
    handleEmployee(emailAddress, firstName, lastName, department);
    setModalOpen(false);
  };

  return (
    <div className="modal display-block">
      <div className="modal__content">
        <h4 className="modal__content__heading">{title}</h4>

        {/*
        <CustomDropdown
          placeholder="Välj personalgrupp"
          list={departments}
          value={department}
          defaultValue={initialDepartment}
          setValue={setDepartment}
        />
        */}

        <div className="modal__content__form">
          <h4 className="modal__content__heading--sub">Personliga uppgifter</h4>
          <input
            className="modal__content__form__input"
            type="text"
            placeholder="Förnamn"
            value={firstName}
            onChange={setFirstName}
          />
          <input
            className="modal__content__form__input"
            type="text"
            placeholder="Efternamn"
            value={lastName}
            onChange={setLastName}
          />
        </div>
        <div className="modal__content__form">
          <h4 className="modal__content__heading--sub">Arbetsplatsuppgifter</h4>
          <input
            className="modal__content__form__input"
            type="text"
            placeholder="Epost"
            value={emailAddress}
            onChange={setEmailAddressName}
          />
          <CustomDropdown
            placeholder="Välj personalgrupp"
            list={departments}
            value={department}
            setValue={setDepartment}
            style={{ marginTop: "1.5rem" }}
            employeeTheme={true}
          />
        </div>
        <div className="modal__content__button-row">
          <button
            className="modal__content__button-empty"
            onClick={() => setModalOpen(false)}
          >
            Avbryt
          </button>
          <button
            className="App__button App__button--orange App__button--small"
            onClick={handleSubmit}
          >
            {buttonText || "Lägg till"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
