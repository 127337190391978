import React from "react";

import { Container, Col, Row } from "react-bootstrap";
import minus from "../../Assets/icons/Minus.png";
import Sticky from "react-stickynode";

const FaqViewBottomSection = () => {

  const element = document.getElementById('test')
  const element2 = document.getElementById('test2')
  // const domRect = element.getBoundingClientRect();
  if(element)
  console.log(Math.abs(element.getBoundingClientRect().top), 'element1')

  if(element2)
  console.log(Math.abs(element2.getBoundingClientRect().top), 'element2')

  return (
    <section className="faqView__Bottomsection">
      <Container>
        <Row>
          <Col lg={4} md={4} className="d-none d-md-block">
            <Sticky bottomBoundary="#stickmenu-bottom-border">
              <div className="faqView__Bottomsection__menu__container">
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Hur kommer du igång
                  </span>
                </Row>
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Varför skall du använda oss?
                  </span>
                </Row>
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Säkerhet
                  </span>
                </Row>
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Betalning
                  </span>
                </Row>
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Våra vanligaste frågor
                  </span>
                </Row>
                <Row className="faqView__Bottomsection__menu__row">
                  <img
                    src={minus}
                    alt="text"
                    className="faqView__Bottomsection__icon"
                  />
                  <span
                    className={`faqView__Bottomsection__menu__text`}
                  >
                    Hur kommer du igång
                  </span>
                </Row>
              </div>
            </Sticky>
          </Col>
          <Col lg={8} md={8} >
            <div className="faqView__Bottomsection__questions__container">
              <h3 className="faqView__Bottomsection__questions__heading">
                Hur du kommer igång
              </h3>
              <div  id="test">
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>
              <h3 className="faqView__Bottomsection__questions__heading">
                Varför skall du använda oss?
              </h3>
              <div id="test2">
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>
              <h3 className="faqView__Bottomsection__questions__heading">
                Säkerhet
              </h3>
              <div className="faqView__Bottomsection__questions__question">
                Vad är det första jag gör?
                <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
              </div>
              <div >
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>
              <h3 className="faqView__Bottomsection__questions__heading">
                Betalning
              </h3>
              <div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>

              <h3 className="faqView__Bottomsection__questions__heading">
                Våra vanligaste frågor
              </h3>
              <div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>

              <h3
                id="stickmenu-bottom-border"
                className="faqView__Bottomsection__questions__heading"
              >
                Hur du kommer igång
              </h3>
              <div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
                <div className="faqView__Bottomsection__questions__question">
                  Vad är det första jag gör?
                  <i className="fas fa-plus faqView__Bottomsection__questions__question__icon"></i>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqViewBottomSection;
