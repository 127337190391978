import React, { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { formSlice } from "../../Redux/FormSlice";
// import minusIcon from '../../Assets/icons/Minus.png';
// import plusIcon from '../../Assets/icons/Plus.svg';

import Navbar from "../../Components/TopNavbar";
import LeftNavbar from "../../Components/LeftNavbar";
import DashboardStart from "./DashboardSubViews/DashboardStart";
import DashboardStaff from "./DashboardSubViews/DashboardStaff";
import DashboardSendouts from "./DashboardSubViews/DashboardMyForms";
import DashboardTemplates from "./DashboardSubViews/DashboardTemplates";
import axios from "axios";
import { fetchDepartments } from "../../Redux/DepartmentSlice";
import InspectForm from "../../Components/InspectForm";
import DashboardCreateForm from "./DashboardSubViews/DashboardCreateForm";
import { userSelector } from "../../Redux/UserSlice";

const DashboardStartView = ({history}) => {
	const [menu] = useState(true)

	let match = useRouteMatch();

	const formActions = formSlice.actions;

	const dispatch = useDispatch();
	const {isLoggedIn} = useSelector(userSelector)

	// Push out any1 that is not logged in
	useEffect(() => {
		if(!isLoggedIn){
			history.push('/')
		}
	}, [isLoggedIn, history])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const fetchData = async() => {
		const dbForms = await axios.get(
			`${process.env.REACT_APP_DOMAIN}/api/dashboard/Get?organizationId=1`
		);
		dispatch(formActions.setForms(dbForms.data.Forms));
	}

	useEffect(() => {
		let isMounted = true
		const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
			// setState(state => ({ data: state.data, error: false, loading: true }))
			if(isMounted){
				fetchData()
			}		
		}, 30000)

		return () => {
			clearInterval(intervalId); //This is important
			isMounted = false // Let's us know the component is no longer mounted.
		}

	}, [fetchData])

	// Get all the forms
	useEffect(() => {
		async function fetchData() {
			const dbForms = await axios.get(
				`${process.env.REACT_APP_DOMAIN}/api/dashboard/Get?organizationId=1`
			);
			dispatch(formActions.setForms(dbForms.data.Forms));
		}
		fetchData();
	}, [formActions, dispatch]);

	// Get all the apartments
	useEffect(() => {
		dispatch(fetchDepartments());
	}, [dispatch]);

	return (
		<div className="dashboardStartView">
			<Navbar backgroundColor={"black"}/>
			<Container>
				<div className="dashboardStartView__flex-container">
					
					{ menu && 
						<div className="dashboardStartView__flex-container__menu App-small-hide" style={{ backgroundColor: "#FFFFFF" }}>
							<LeftNavbar />
						</div>
					}
					<div className="dashboardStartView__flex-container__dashboard">
						{/*<img 
							onClick={toggleMenu} 
						src={menu ? minusIcon : plusIcon} alt="meny" className="dashboardStartView__flex-container__dashboard__icon" /> */}
						<Switch>
							<Route exact path={`${match.path}/`} component={DashboardStart} />
							<Route
								exact
								path={`${match.path}/minautskick/undersokning/:FormId/:EditionId/:DepartmentId`}
								component={InspectForm}
							/>
							<Route
								exact
								path={`${match.path}/personal`}
								component={DashboardStaff}
							/>
							<Route
								path={`${match.path}/minautskick`}
								component={DashboardSendouts}
							/>
							<Route
								exact
								path={`${match.path}/minamallar`}
								component={DashboardTemplates}
							/>
							<Route
								exact
								path={`${match.path}/minamallar/skapamall`}
								component={DashboardCreateForm}
							/>
						</Switch>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default DashboardStartView;
