import { createSlice } from "@reduxjs/toolkit";

let user = {
  
};

export const userSlice = createSlice({
  name: "user",

  initialState: {
    user,
    isLoggedIn: false
  },

  reducers: {
    resetUser: (state) => {
      state.isLoggedIn = false;
      state.user = {}
    },
    setUser: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;     
    },
  },
});

export default userSlice.reducer;

// Three actions generated from the slice
export const {
  resetUser,
  setUser,
} = userSlice.actions;

// Export A selector
export const userSelector = (state) => state.user;

export function logoutUser() {
  return async (dispatch) => {
    dispatch(resetUser());
  };
}