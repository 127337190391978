import React from "react";
import useFormState from "../Hooks/useFormState";

const EditDepartmentModal = ({ editModalOpen, createMode=true, addDepartmentHandler }) => {
    const [name, setName] = useFormState("");

    return (
        <div className="modal display-block">
            <div className="modal__content">
                <h4 className="modal__content__heading">Lägg till avdelning</h4>
                <form onSubmit={(e) => createMode ? addDepartmentHandler(e, name) : null}>
                    <input required type="text" placeholder="Ange namn för avdelningen" className="App__textinput" name={name} value={name} onChange={setName} />
                    <div className="modal__content__button-row">
                        <button
                            className="modal__content__button-empty"
                            onClick={() => editModalOpen(false)}
                        >
                            Avbryt
                        </button>
                        <button
                            type="submit"
                            className="App__button App__button--orange App__button--small"
                        >
                            Skicka
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditDepartmentModal;
