import React, { useState, useRef, useEffect } from "react";
import openIcon from "../Assets/icons/Open.svg";
import searchIcon from "../Assets/icons/Search.svg";

const CustomDropdown = ({
  placeholder,
  list = [{ Name: "Standardmall", Id: 1233124142 }],
  setValue,
  value,
  employeeTheme = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const handleSelection = (e) => {
    setValue(e.target.innerHTML);
    setDropdownOpen(false);
  };

  return (
    <div className="customdropdown" ref={myRef}>
      <div
        className={`customdropdown__dropdown-row ${
          employeeTheme ? "customdropdown__dropdown-row--employee-theme" : null
        }`}
        onClick={toggleDropdown}
        style={{}}
      >
        {value || placeholder}
        <img src={openIcon} alt="meny" />
      </div>
      {dropdownOpen && (
        <ul className="customdropdown-list">
          <div className="customdropdown-list__search-row">
            <img src={searchIcon} alt="sökikon" />
            <input
              type="text"
              className="customdropdown__search"
              placeholder={"Sök efter namn"}
            />
          </div>
          {list.map((x) => (
            <li
              className="customdropdown-list__item"
              key={x.Id}
              onClick={(e) => handleSelection(e)}
              value={x.Name}
            >
              {x.Name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;

// const [clickedOutside, setClickedOutside] = useState(false);
// const myRef = useRef();

// const handleClickOutside = (e) => {
//   if (!myRef.current.contains(e.target)) {
//     setClickedOutside(true);
//   }
// };

// const handleClickInside = () => setClickedOutside(false);

// useEffect(() => {
//   document.addEventListener("mousedown", handleClickOutside);
//   return () => document.removeEventListener("mousedown", handleClickOutside);
// });

// return (
//   <button ref={myRef} onClick={handleClickInside}>
//     {clickedOutside ? "Bye!" : "Hello!"}
//   </button>
// );
