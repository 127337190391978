import React, { useState, useEffect } from "react";
import axios from "axios";

import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import useFormState from "../../Hooks/useFormState";
import { useDispatch, useSelector } from "react-redux";
import { userSelector, userSlice } from "../../Redux/UserSlice";

const LoginView = ({ history }) => {
  const [email, setEmail] = useFormState("");
  const [password, setPassword] = useFormState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState(false);

  const actions = userSlice.actions;

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!forgotPassword) {
      // Login in logic
      axios
        .get(
          `${process.env.REACT_APP_DOMAIN}/api/login/loginuser?Email=${email}&password=${password}`
        )
        .then((res) => dispatch(actions.setUser(res.data)))
        .catch((err) => {
          console.log(err);
          setError(true);
        });
    } else {
      // Forgotpassword logic
      axios.get(
        `${process.env.REACT_APP_DOMAIN}/api/login/reset?email=${email}&expireswhen`
      );
    }
  };

  const { isLoggedIn } = useSelector(userSelector);

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/dashboard");
    }
  }, [isLoggedIn, history]);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter" || e.code === "NumpadEnter") {
        handleSubmit(e);
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });

  return (
    <section>
      <Container fluid className="loginView__container">
        <Row>
          <Col lg={5}>
            <Row>
              <Link to="/" className="loginView__homelink">
                medarbetarpulsen.io
              </Link>
            </Row>
            <Row>
              <h2 className="loginView__heading">
                {!forgotPassword ? "Logga in här" : "Glömt Lösenord"}
              </h2>
            </Row>
            <Row>
              <p className="loginView__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
                faucibus malesuada ultrices laoreet. Fringilla est justo,.
              </p>
            </Row>
            <form className="loginView__form">
              <Row>
                {!forgotPassword ? (
                  <React.Fragment>
                    <input
                      type="text"
                      placeholder="E-mail"
                      value={email}
                      onChange={setEmail}
                      className="App__textinput loginView__textInput"
                    />

                    <input
                      type="password"
                      placeholder="Lösenord"
                      value={password}
                      onChange={setPassword}
                      className="App__textinput loginView__textInput"
                    />
                  </React.Fragment>
                ) : (
                  <input
                    type="text"
                    placeholder="E-mail"
                    value={email}
                    onChange={setEmail}
                    className="App__textinput loginView__textInput"
                  />
                )}
              </Row>
              <Row>
                <span
                  className="loginView__forgotPassword"
                  onClick={() => setForgotPassword(!forgotPassword)}
                >
                  {!forgotPassword
                    ? "Glömt Lösenordet?"
                    : "Tillbaka till inloggning"}
                </span>
              </Row>
              <Row>
                <button
                  className="App__button App__button--orange"
                  style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
                  onClick={(e) => handleSubmit(e)}
                  type="submit"
                >
                  {!forgotPassword ? "Logga in" : "Skicka återställningslänk"}
                </button>
                {error && (
                  <p className="loginView__error-message">
                    Fel E-mail eller lösenord
                  </p>
                )}
              </Row>
            </form>
          </Col>
          <Col
            lg={7}
            style={{ paddingRight: "0" }}
            className="d-none d-lg-block"
          >
            <img
              src={"./Images/LoginView/Login_hero.png"}
              alt="Login_bild"
              className="loginView__hero-image"
            ></img>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default LoginView;
