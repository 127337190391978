import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const HomeViewTopSection = () => {
  return (
    <section className="HomeView__topsection">
      <Container>
        <Row>
          <Col lg={7} md={12}>
            <h1 className="HomeView__topsection__heading-1">
              Vad säger vem om vad?
              <br />
              Få svaret idag
            </h1>
            <form>
              <input
                type="text"
                name="email"
                className="HomeView__topsection__textinput"
                placeholder="Email"
              />
              <button className="HomeView__topsection__textinput__button">
                Anmäl dig
              </button>
              <p className="HomeView__topsection__text">
                Få dom senaste uppdateringarna!
              </p>
            </form>
          </Col>
          <Col lg={5} md={12}>
            <figure className="HomeView__topsection__figure">
              <img
                className="HomeView__topsection__figure__image"
                src={"./Images/HomeView/Index__office.jpg"}
                alt="kontorsbild"
              />
            </figure>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeViewTopSection;
