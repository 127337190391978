import { configureStore } from "@reduxjs/toolkit";
import departmentSlice from "./DepartmentSlice";
import { formSlice } from "./FormSlice";
import { userSlice } from "./UserSlice";

const store = configureStore({
  reducer: {
    departments: departmentSlice,
    form: formSlice.reducer,
    user: userSlice.reducer,
  },
});

export default store;
