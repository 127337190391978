import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { formSlice } from "../Redux/FormSlice";
import CustomDropdown from "./CustomDropdown";
import axios from "axios";

const SendFormModal = ({ setModalOpen }) => {
  const [department, setDepartment] = useState("");
  const departments = useSelector((state) => state.departments.departments);

  const [form, setForm] = useState("");

  const dispatch = useDispatch();
  const formActions = formSlice.actions;

  async function fetchData() {
    console.log('fetchdata ran')
    const dbForms = await axios.get(
      `${process.env.REACT_APP_DOMAIN}/api/dashboard/Get?organizationId=1`
    );
    dispatch(formActions.setForms(dbForms.data.Forms));
  }

  const handleSubmit = async () => {
    const deparmentToSend = departments.filter((d) => d.Name === department)[0];
    // ADD LOGIC FOR SUBMIT FORM
    const data = { FormId: 3, department: deparmentToSend };
    await axios.post(`${process.env.REACT_APP_DOMAIN}/api/form/SendForm`, data);
    fetchData();
    setModalOpen(false);
  };
  return (
    <div className="modal display-block">
      <div className="modal__content">
        <h4 className="modal__content__heading">Vem skall få mailet?</h4>
        <CustomDropdown
          placeholder="Välj personalgrupp"
          list={departments}
          value={department}
          setValue={setDepartment}
        />

        <h4 className="modal__content__heading">Vilken undersökning</h4>
        <CustomDropdown
          placeholder="Välj utskicksmall"
          value={form}
          setValue={setForm}
        />
        <div className="modal__content__black-box">
          <h4 className="modal__content__black-box__heading">
            Information om utskick
          </h4>
          <p className="modal__content__black-box__text">
            Lite tänk på detta information kanske om vad som händer när mailet
            går ut och detta skriver jag för att fylla ut text.
          </p>
        </div>
        <div className="modal__content__button-row">
          <button
            className="modal__content__button-empty"
            onClick={() => setModalOpen(false)}
          >
            Avbryt
          </button>
          <button
            className="App__button App__button--orange App__button--small"
            onClick={handleSubmit}
          >
            Skicka
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendFormModal;
