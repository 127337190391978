import React from "react";
import iconUnchecked from "../Assets/icons/Unchecked.svg";
import iconChecked from "../Assets/icons/Checked-green.svg";
import moment from "moment";
import { Link } from "react-router-dom";

const SurveyOverview = ({ form }) => {
  const completed = form.AnswerCount===form.TotalCount
  return (
    <Link
        to={`/dashboard/minautskick/undersokning/${form.Id}/${form.LatestEdition}/${form.DepartmentId}`}
        className="surveyOverview"
      >
      <div className="surveyOverview__heading">{form.Name}</div>
      
      <div className="surveyOverview__workspace-info">
        <span>{moment(form.DateCreated).format("DD.MM.YYYY - HH.mm")}</span>
        <span>{form.Location}</span>
      </div>
      <div className="surveyOverview__question">{form.Question}</div>
      <div className="surveyOverview__bottomRow-info">
        <div className="surveyOverview__bottomRow-info__numbers">
          <span className="surveyOverview__bottomRow-info__numbers-text">
            BESVARADE
          </span>
          <span>{form.AnswerCount}/{form.TotalCount}</span>
        </div>
        <img
          className={`surveyOverview__bottomRow-info__icon ${completed && 'surveyOverview__bottomRow-info__icon--checked'}`}
          src={completed ? iconChecked : iconUnchecked}
          alt="ofärdig undersökning"
        />
      </div>
    </Link>
  );
};

export default SurveyOverview;
