import React, { useState } from "react";
import iconUpGreen from "../../../Assets/icons/Arrow-up-green.svg";
import iconUpGray from "../../../Assets/icons/Arrow-up-grey.svg";
import iconDownGray from "../../../Assets/icons/Arrow-down-grey.svg";
import iconDownGreen from "../../../Assets/icons/Arrow-down-green.svg";
import trashcan from "../../../Assets/icons/Trashcan-green.svg";

const CreateQuestionsBlockQuestion = ({
  question,
  index,
  changeQuestionHandler,
  removeQuestionHandler,
  length,
  rearrangeQuestionsHandler,
  numericalAnswer,
  toggleQuestionValue,
}) => {
  const [hover, setHover] = useState(false);

  const arrowUp = (index) => {
    return index === 0 ? iconUpGray : iconUpGreen;
  };
  const arrowDown = (index, length) => {
    return index === length - 1 ? iconDownGray : iconDownGreen;
  };

  const classNameUp = (index) => {
    return index === 0
      ? "createQuestionsBlock__white-box__edit__icon createQuestionsBlock__white-box__edit__icon--grey"
      : "createQuestionsBlock__white-box__edit__icon";
  };

  const classNameDown = (index, length) => {
    return index === length - 1
      ? "createQuestionsBlock__white-box__edit__icon createQuestionsBlock__white-box__edit__icon--grey"
      : "createQuestionsBlock__white-box__edit__icon";
  };

  return (
    <div
      className="createQuestionsBlock__question"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="createQuestionsBlock__num">{index + 1}</div>
      <div className="createQuestionsBlock__white-box">
        <div className="createQuestionsBlock__white-box__question">
          <div>
            <div
              className="createQuestionsBlock__white-box__label"
              onClick={() => toggleQuestionValue("numericalAnswer", index)}
            >
              {question.numericalAnswer ? "Svarintervall" : "Fritextsvar"}
            </div>
            <div
              className="createQuestionsBlock__white-box__label"
              onClick={() => toggleQuestionValue("isRequired", index)}
            >
              Svar {question.isRequired ? "obligatoriskt" : "valfritt"}
            </div>
          </div>
          <input
            className="createQuestionsBlock__white-box__text-input"
            type="text"
            placeholder="Skriv ner rubrik"
            value={question.title}
            onChange={(e) => changeQuestionHandler(e, index)}
            name="title"
          />
          <textarea
            className="createQuestionsBlock__white-box__textarea"
            type="text"
            placeholder="Ställ frågan"
            value={question.question}
            onChange={(e) => changeQuestionHandler(e, index)}
            rows={3}
            name="question"
          />
        </div>
        {hover && (
          <div className="createQuestionsBlock__white-box__edit">
            <img
              className={classNameUp(index)}
              src={arrowUp(index)}
              alt="pil up"
              onClick={() => rearrangeQuestionsHandler(index, "up")}
            />
            <img
              className="createQuestionsBlock__white-box__edit__icon"
              src={trashcan}
              alt="ta bort fråga"
              onClick={() => removeQuestionHandler(index)}
            />
            <img
              className={classNameDown(index, length)}
              src={arrowDown(index, length)}
              alt="pil ner"
              onClick={() => rearrangeQuestionsHandler(index, "down")}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateQuestionsBlockQuestion;
