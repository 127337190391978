import React, { useState } from "react";
import CreateQuestionsBlockQuestion from "./CreateQuestionsBlockQuestion";


const CreateQuestionsBlock = () => {
  const [questions, setQuestions] = useState([]);

  const addQuestionHandler = (numericalAnswer = true) => {
    setQuestions([
      ...questions,
      {
        index: questions.length,
        title: "",
        numericalAnswer,
        isRequired: true,
      },
    ]);
  };
  const removeQuestionHandler = (index) => {
    let array = [...questions];
    if (index !== -1) {
      array.splice(index, 1);
      setQuestions(array);
    }
  };

  const changeQuestionHandler = (e, index) => {
    let newState = [...questions];
    newState[index][e.target.name] = e.target.value;
    setQuestions([...newState]);
  };

  const toggleQuestionValue = (name, index) => {
    let newState = [...questions];
    newState[index][name] = !questions[index][name];
    setQuestions(newState);
  };

  const changeValuePosition = (arr, init, target) => {
    [arr[init], arr[target]] = [arr[target], arr[init]];
    return arr;
  };

  const rearrangeQuestionsHandler = (index, action) => {
    if (
      (index > 0 && action === "up") |
      (index !== questions.length - 1 && action === "down")
    ) {
      let newState = [...questions];
      if (action === "up") {
        const newIndex = index - 1;
        newState = changeValuePosition(newState, index, newIndex);
      } else if (action === "down") {
        const newIndex = index + 1;
        newState = changeValuePosition(newState, index, newIndex);
      }
      setQuestions([...newState]);
    } else {
      console.log("should not move");
    }
  };

  return (
    <div className="createQuestionsBlock">
      {questions.map((question, index) => (
        <CreateQuestionsBlockQuestion
          key={index}
          index={index}
          question={question}
          changeQuestionHandler={changeQuestionHandler}
          removeQuestionHandler={removeQuestionHandler}
          length={questions.length}
          rearrangeQuestionsHandler={rearrangeQuestionsHandler}
          toggleQuestionValue={toggleQuestionValue}
        />
      ))}
      <div className="createQuestionsBlock__button-row">
        <div>
          <button
            className="App__button App__button--green App__button--small"
            onClick={() => addQuestionHandler(true)}
          >
            Lägg till fråga
          </button>
          <button
            className="App__button createQuestionsBlock__white-box__special-button"
            onClick={() => addQuestionHandler(false)}
          >
            Lägg till fritextfält
          </button>
        </div>
        <button className="App__button App__button--orange App__button--small">
          Spara formulär
        </button>
      </div>
    </div>
  );
};

export default CreateQuestionsBlock;


