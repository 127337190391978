import React from 'react'
import { RadialChart } from 'react-vis'

const InfoDisplayBlock = () => {
    return (
        <div className="infoDisplayblock">
            <div className="infoDisplayblock-flexItem">
                <div className="infoDisplayblock-flexItem__info">
                    <div className="infoDisplayblock-flexItem__info-title">
                        Svarsfrekvens
                    </div>
                    <div className="infoDisplayblock-flexItem__info-num">
                        80%
                    </div>
                </div>
                <RadialChart
                    data={[
                        { angle: 15, radius: 45, innerRadius: 40, className: "test" },
                        { angle: 5, radius: 45, innerRadius: 40, className: "test-2" },
                    ]}
                    width={240}
                    height={240} />
            </div>
            <div className="infoDisplayblock-flexItem">
                <div className="infoDisplayblock-flexItem__gray-border">
                <div className="infoDisplayblock-flexItem__info-title">
                    Motivation
                </div>
                <div className="infoDisplayblock-flexItem__info-num">
                    8/10
                </div>
                </div>
            </div>
            <div className="infoDisplayblock-flexItem">
                <div className="infoDisplayblock-flexItem__gray-border">
                    <div className="infoDisplayblock-flexItem__info-title">
                        Totalt antal svar
                </div>
                    <div className="infoDisplayblock-flexItem__info-num">
                        28
                </div>
                </div>
            </div>
        </div>
    )
}

export default InfoDisplayBlock
