import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { submitUserForm } from "../../../Redux/FormSlice";
import DisplayQuestionsBlockQuestion from "./DisplayQuestionsBlockQuestion";

const DisplayQuestionsBlock = ({ intialQuestions, Form, Token }) => {
  const [Answers, setAnswers] = useState([...intialQuestions]);

  const dispatch = useDispatch();

  const changeStringAnswerHandler = (e, index) => {
    let newState = [...Answers];
    newState[index]["StringAnswer"] = e.target.value;
    setAnswers([...newState]);
  };

  const changeIntAnswerHandler = (e, index) => {
    let newState = [...Answers];
    newState[index]["IntAnswer"] = e.target.getAttribute('value');
    setAnswers([...newState]);
  } 

  const submitFormHandler = async (e) => {
    e.preventDefault();
    dispatch(submitUserForm({Form, Answers, Token}))
  };

  return (
    <form className="displayQuestionsBlock">
      {Answers.map((question, index) => (
        <DisplayQuestionsBlockQuestion
          key={index}
          index={index}
          question={question}
          changeStringAnswerHandler={changeStringAnswerHandler}
          changeIntAnswerHandler={changeIntAnswerHandler}
        />
      ))}
      <div className="displayQuestionsBlock__button-row">
        <button
          type="submit"
          className="App__button App__button--orange App__button--small"
          onClick={(e) => submitFormHandler(e)}
        >
          Skicka in svar
        </button>
      </div>
    </form>
  );
};

export default DisplayQuestionsBlock;
