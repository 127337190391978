import React from "react";

const DashboardTemplates = ({ history }) => {
  const clickHandler = () => {
    history.push("/dashboard/minamallar/skapamall");
  };
  return (
    <div
      className="dashboardStartView__container"
      style={{ backgroundColor: "#E5E5E5", minHeight: "800px" }}
    >
      <h3 className="App__dashboard__heading--large">Mallar</h3>
      <p className="App__dashboard__info-text App__dashboard__info-text--full-width">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id faucibus
        malesuada ultrices laoreet. Fringilla est justo,.
      </p>
      <button
        className="App__button App__button--orange"
        onClick={clickHandler}
      >
        Skapa ny mall
      </button>
      <h4 className="App__dashboard__heading">Mina mallar</h4>
    </div>
  );
};

export default DashboardTemplates;
