import React, { useState } from "react";
import axios from "axios";

import { Col, Row, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import useFormState from "../../Hooks/useFormState";

const ResetView = ({ history }) => {
  const [password, setPassword] = useFormState("");
  const [password2, setPassword2] = useFormState("");
  const [error, setError] = useState("");

  const { Token } = useParams();

  const handleSubmit = async () => {
    console.log(password, 'password', 'password2', password2)
    setError("")
    if(password !== password2){
      setError("Lösenorden måste vara lika")
    } else {
      axios.get(`${process.env.REACT_APP_DOMAIN}/api/..`, {
        password,
        Token
      })
    }
  };

  return (
    <section>
      <Container fluid className="loginView__container">
        <Row>
          <Col lg={5}>
            <Row>
              <Link to="/" className="loginView__homelink">
                medarbetarpulsen.io
              </Link>
            </Row>
            <Row>
              <h2 className="loginView__heading">
                Återställning lösenord
              </h2>
            </Row>
            <Row>
              <p className="loginView__text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id
                faucibus malesuada ultrices laoreet. Fringilla est justo,.
              </p>
            </Row>
            <Row>
              <form>
                <input
                  type="password"
                  placeholder="Lösenord"
                  value={password}
                  onChange={setPassword}
                  className="App__textinput loginView__textInput"
                />

                <input
                  type="password"
                  placeholder="Återupprepa lösenord"
                  value={password2}
                  onChange={setPassword2}
                  className="App__textinput loginView__textInput"
                />
              </form>
            </Row>
            <Row>
              <button
                className="App__button App__button--orange"
                style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
                onClick={handleSubmit}
              >
                Återställ lösenord
              </button>
              {error.length > 0 && <p className="loginView__error-message">{error}</p>}
            </Row>
          </Col>
          <Col lg={7} style={{ paddingRight: "0" }} className="d-none d-lg-block">
            <img
              src={"../Images/LoginView/Login_hero.png"}
              alt="Login_bild"
              className="loginView__hero-image"
            ></img>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetView;
