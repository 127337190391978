import React, { useEffect } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { Container } from "react-bootstrap";

import { Fragment } from "react";
import DisplayQuestionsBlock from "../../Components/CustomBlocks/DisplayQuestionsBlock/DisplayQuestionsBlock";
import { useSelector } from "react-redux";
import { formsSelector } from "../../Redux/FormSlice";

const FormView = () => {
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState("");
  const { Token } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        const formReponse = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/form/ValidateFormToken?formToken=${Token}`
        );
        setForm(formReponse.data);
        setLoading(false);
      } catch (error) {
      }    
    }

    fetchData();
  }, [Token]);

  const {formSubmit} = useSelector(formsSelector)

  return (
    <div className="formView">
      {formSubmit ? <div>Dina svar är inskickade, tack för din medverkan.</div> : (
        loading ? (
          <div className="App__flex-center">laddar...</div>
        ):(
            <Fragment>
              <div className="formView__green-box">
                <Container
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                  }}
                >
                  <div className="formView__green-box__content">
                    <h3 className="formView__green-box__heading">{form.Name}</h3>
                    <div className="formView__green-box__info-text">
                      Frågorna i denna enkät ligger för grund till vårt kommande
                      medarbetarsamtal
                </div>
                  </div>
                </Container>
              </div>
              <Container>
                <DisplayQuestionsBlock intialQuestions={form.Questions} Form={form} Token={Token} />
              </Container>
            </Fragment>
          )
      )}
    </div>
  );
};

export default FormView;
