import React from "react";
import { useSelector } from "react-redux";
import InfoDisplayBlock from "../../../Components/CustomBlocks/InfoDisplayBlock";
import SurveyOverview from "../../../Components/FormOverviewCard";

const DashboardStart = () => {
  const forms = useSelector((state) => state.form.forms);
  return (
    <div
      className="dashboardStartView__container"
      style={{ backgroundColor: "#E5E5E5", minHeight: "800px" }}
    >
      <h3 className="App__dashboard__heading--large">Lite grafer</h3>
      <InfoDisplayBlock />
      
      <h4 className="App__dashboard__heading">Senaste undersökningarna</h4>
      <div className="App__dashboard-surveyOverview-container">
        {forms.length > 0 &&
          forms.map((form) => {
            return <SurveyOverview key={form.Id} form={form} />;
          })}
      </div>
    </div>
  );
};

export default DashboardStart;
