import React from "react";

const ResponseDisplay = ({ answers = [] }) => {
  const getClass = (value) => {
    switch (true) {
      case value >= 8:
        return "responseDisplay__card__num-col__num responseDisplay__card__num-col__num--green";
      case value <= 3 && value !== 0:
        return "responseDisplay__card__num-col__num responseDisplay__card__num-col__num--red";
      default:
        return "responseDisplay__card__num-col__num";
    }
  };
  return (
    <div className="responseDisplay">
      {answers.map((answer, i) => {
        const maxClass = getClass(answer.Max);
        const minClass = getClass(answer.Min);
        const avgClass = getClass(answer.Avg);
        return (
          <div className="responseDisplay__card" key={i}>
            <div>
              <div className="responseDisplay__card__heading">
                {answer.QuestionTitle}
              </div>
              <p className="responseDisplay__card__info">{answer.Question}</p>
            </div>
            <div className="responseDisplay__card__num-row">
              <div className="responseDisplay__card__num-col">
                <div className="responseDisplay__card__num-col__info">
                  Lägsta
                </div>
                <div className={minClass}>{answer.Min > 0 ? answer.Min : "-"}</div>
              </div>
              <div className="responseDisplay__card__num-col">
                <div className="responseDisplay__card__num-col__info">
                  Högsta
                </div>
                <div className={maxClass}>{answer.Max > 0 ? answer.Max : "-"}</div>
              </div>
              <div className="responseDisplay__card__num-col">
                <div className="responseDisplay__card__num-col__info">
                  Genomsnitt
                </div>
                <div className={avgClass}>
                  {answer.Avg > 0 ? (Math.round(answer.Avg * 10) / 10).toString().replace(".", ",") : "-"}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ResponseDisplay;
