import React, { useEffect, useRef, useState } from "react";

import HandleEmployeeModal from "../Components/HandleEmployeeModal";
import editDots from "../Assets/icons/Edit-dots.svg";

import { useDispatch, useSelector } from "react-redux";
import {
  departmentsSelector,
  editEmployeeAction,
  removeEmployeeAction,
} from "../Redux/DepartmentSlice";

const EditStaffCard = ({ staff, department, ableToEditStaff }) => {
  const [editModal, setEditModal] = useState(false);
  const [listOpen, setListOpen] = useState(false);

  const { departments } = useSelector(departmentsSelector);

  const dispatch = useDispatch();

  const toggleList = () => {
    setListOpen(!listOpen);
  };

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const removeEmployeeHandler = () => {
    toggleList();
    dispatch(removeEmployeeAction(staff.EmailAddress, department.Id));
  };

  const updateEmployeeHandler = async (
    EmailAddress,
    FirstName,
    LastName,
    departmentName
  ) => {
    const newDepartmentArray = departments.filter(
      (x) => x.Name === departmentName
    );

    const data = {
      EmailAddress,
      FirstName,
      LastName,
      Id: staff.Id,
      DepartmentId: newDepartmentArray[0].Id,
      OldDepartmentId: department.Id,
    };

    dispatch(editEmployeeAction(data));
  };

  return (
    <div className="editStaffCard">
      <span className="editStaffCard__name">
        {staff.FirstName} {staff.LastName}
      </span>
      <span className="editStaffCard__email">
        {staff.EmailAddress}

        <div className="editStaffCard__dropdown-container" ref={myRef}>
          <div onClick={toggleList}>
            {ableToEditStaff && <img
              src={editDots}
              alt="meny"
              className="editStaffCard__edit-dots"
            />}
          </div>
          {listOpen && (
            <ul className="editStaffCard__dropdown-list">
              {/* 
              <ul className="editStaffCard__dropdown-list__sublist">s</ul>
              <li className="editStaffCard__dropdown-list__item">Flytta</li>
              */}
              <li
                className="editStaffCard__dropdown-list__item"
                onClick={() => setEditModal(true)}
              >
                Redigera
              </li>
              <li
                className="editStaffCard__dropdown-list__item"
                style={{ color: "#D44333" }}
                onClick={removeEmployeeHandler}
              >
                Ta bort
              </li>
            </ul>
          )}
        </div>
      </span>
      {editModal && (
        <HandleEmployeeModal
          title={"Uppdatera Personal"}
          setModalOpen={setEditModal}
          handleEmployee={updateEmployeeHandler}
          buttonText={"Uppdatera"}
          initialFirstName={staff.FirstName}
          initialLastName={staff.LastName}
          initialEmail={staff.EmailAddress}
          initialDepartment={department.Name}
        />
      )}
    </div>
  );
};

export default EditStaffCard;
